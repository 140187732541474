import React from 'react'
import CompanyData, {
    DataRow,
    Labeled
} from 'src/components/contact/CompanyData'
import { ContactData, useContactData } from 'src/components/contact/ContactData'
import ContactForm from 'src/components/contact/ContactForm'
import GarwanMap from 'src/components/contact/GarwanMap'
import Container from 'src/components/Container'
import Layout from 'src/components/Layout'
import PageHeader from 'src/components/PageHeader'
import { t } from 'src/i18n/translation/t'
import { Msgs } from 'src/i18n/translation/types'
import { PageContext } from 'src/page/types'
import { css } from 'src/style/css'
import { Theme } from 'src/style/Theme'
import { grid } from 'src/style/mixins'
import { useTranslateData } from 'src/i18n/useTranslateData'

type CompanyDataColumn = {
    contactData: ContactData
    msgs: Msgs
}

const BillingCompanyData = ({ contactData, msgs }: CompanyDataColumn) => {
    const { address, additional } = contactData.billing
    const trans = msgs.billing
    const tData = useTranslateData()
    return (
        <CompanyData title={trans.title}>
            <DataRow>{address.name}</DataRow>
            <DataRow>{address.street}</DataRow>
            <DataRow>
                {address.postCode} {address.city}
            </DataRow>
            <DataRow>{address.country}</DataRow>
            <br />
            <Labeled title={t(trans.registered)}>
                {tData(additional.registered)}
            </Labeled>
            <Labeled title={t(trans.companyId)}>{additional.companyId}</Labeled>
            <Labeled title={t(trans.vatId)}>{additional.vatId}</Labeled>
            <Labeled title={t(trans.taxId)}>{additional.taxId}</Labeled>
        </CompanyData>
    )
}

const BankCompanyData = ({ contactData, msgs }: CompanyDataColumn) => {
    const { address, additional } = contactData.billing.bank
    const trans = msgs.billing.bank
    return (
        <CompanyData title={trans.title}>
            <DataRow>{address.name}</DataRow>
            <DataRow>{address.street}</DataRow>
            <DataRow>
                {address.postCode} {address.city}
            </DataRow>
            <DataRow>{address.country}</DataRow>
            <br />
            <Labeled title={t(trans.accountId)}>{additional.accountId}</Labeled>
            <Labeled title={t(trans.swift)}>{additional.swift}</Labeled>
            <Labeled title={t(trans.iban)}>{additional.iban}</Labeled>
        </CompanyData>
    )
}

const ContactCompanyData = ({ contactData, msgs }: CompanyDataColumn) => {
    const { contact } = contactData
    const trans = msgs.contact
    return (
        <CompanyData title={trans.title}>
            <Labeled title={t(trans.phone)}>{contact.phone}</Labeled>
            <Labeled title={t(trans.email)}>{contact.email}</Labeled>
        </CompanyData>
    )
}

const ContactPage = ({ pageContext }: { pageContext: PageContext }) => {
    const { msgs } = pageContext
    const { title, subtitle } = msgs.contactPage
    const contactData = useContactData()

    return (
        <Layout pageCtx={pageContext}>
            <PageHeader subtitle={t(subtitle)}>{t(title)}</PageHeader>
            <GarwanMap />
            <Container>
                <div
                    css={(th: Theme) => css`
                        ${grid({
                            minWidth: '20em',
                            gap: `${th.spacing.md} ${th.spacing.sm}`
                        })};
                    `}
                >
                    <BillingCompanyData msgs={msgs} contactData={contactData} />
                    <BankCompanyData msgs={msgs} contactData={contactData} />
                    <ContactCompanyData msgs={msgs} contactData={contactData} />
                </div>
                <ContactForm msgs={msgs} />
            </Container>
        </Layout>
    )
}
export default ContactPage
