import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Map from 'src/components/Map'
import media from 'src/img/media'

type MapApiAndGeoData = {
    map: {
        apiKey: string
    }
    companyGeo: {
        lat: number
        lng: number
    }
}

const useMapApiKeyAndGeoData = (): MapApiAndGeoData => {
    const { contactJson } = useStaticQuery(
        graphql`
            query MapApiQuery {
                contactJson {
                    map {
                        apiKey
                    }
                    companyGeo {
                        lat
                        lng
                    }
                }
            }
        `
    )

    return contactJson
}

type GarwanMap = {
    style?: any
}

const GarwanMap = ({ style }: GarwanMap) => {
    const { map, companyGeo } = useMapApiKeyAndGeoData()
    return (
        <Map
            center={companyGeo}
            zoom={14}
            styles={[
                {
                    stylers: [
                        {
                            saturation: -100
                        }
                    ]
                }
            ]}
            marker={{
                position: companyGeo,
                url: media.marker
            }}
            style={style}
            apiKey={map.apiKey}
        />
    )
}

export default GarwanMap
