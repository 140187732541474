import { graphql, useStaticQuery } from 'gatsby'
import { LangDictionary } from 'src/i18n/translation/types'

export type Address = {
    name: string
    street: string
    city: string
    postCode: string
    country: string
}

export type ContactData = {
    billing: {
        address: Address
        additional: {
            registered: LangDictionary
            companyId: string
            taxId: string
            vatId: string
        }
        bank: {
            address: Address
            additional: {
                accountId: string
                swift: string
                iban: string
            }
        }
    }
    contact: {
        phone: string
        email: string
    }
}

export const useContactData = (): ContactData => {
    const { contactJson } = useStaticQuery(
        graphql`
            query ContactDataQuery {
                contactJson {
                    companyData {
                        billing {
                            address {
                                name
                                street
                                city
                                postCode
                                country
                            }
                            additional {
                                registered {
                                    sk
                                    de
                                    en
                                }
                                companyId
                                taxId
                                vatId
                            }
                            bank {
                                address {
                                    name
                                    street
                                    city
                                    postCode
                                    country
                                }
                                additional {
                                    accountId
                                    swift
                                    iban
                                }
                            }
                        }
                        contact {
                            phone
                            email
                        }
                    }
                }
            }
        `
    )

    return contactJson.companyData
}
