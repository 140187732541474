import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api'
import CSS from 'csstype'
import React from 'react'
import Spinner from 'src/components/Spinner'
import { css } from 'src/style/css'

type MapProps = {
    zoom?: number
    center: {
        lng: number
        lat: number
    }
    marker?: {
        position?: {
            lng: number
            lat: number
        }
        url?: string
    }
    styles?: Record<string, any>
    style?: CSS.Properties
    height?: number
    apiKey: string
}

const Map = ({
    zoom = 15,
    center,
    marker,
    styles,
    style,
    height = 450,
    apiKey
}: MapProps) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: apiKey
    })
    if (loadError) {
        console.error(loadError)
        return <span>Map Load Error</span>
    }

    return isLoaded ? (
        <GoogleMap
            zoom={zoom}
            options={{
                styles: styles
            }}
            mapContainerStyle={{
                height: `${height}px`,
                ...style
            }}
            center={center}
        >
            {marker && marker.position && (
                <Marker
                    position={marker.position}
                    icon={{
                        url: marker.url
                    }}
                />
            )}
        </GoogleMap>
    ) : (
        <div
            css={css`
                height: ${height}px;
                display: flex;
                align-items: center;
                justify-content: center;
            `}
        >
            <Spinner pending={true} />
        </div>
    )
}

export default Map
