import React from 'react'
import FormField from 'src/components/ui/form/FormField'
import { dropdownSelectWithOptions } from 'src/components/ui/kit/inputs/DropdownSelect'
import TextInput from 'src/components/ui/kit/inputs/TextInput'
import { t } from 'src/i18n/translation/t'
import { Msgs } from 'src/i18n/translation/types'
import { css } from 'src/style/css'
import GdprConsent from 'src/components/ui/kit/GdprConsent'
import { valueLabelOptions } from 'src/form/utils'
import { Validator } from 'src/validation/Validator'
import SubmitPanel from 'src/components/ui/form/SubmitPanel'
import { eq, notEmpty } from '@ps-aux/revalid'
import { email } from 'src/validation/rules'
import EmailForm from 'src/components/ui/form/EmailForm'
import { Theme } from 'src/style/Theme'

const contactFormName = 'contact-form'

const validate = Validator({
    name: [notEmpty()],
    email: [email(), notEmpty()],
    company: [notEmpty()],
    message: [notEmpty()],
    gdprConsent: [eq(true)]
}).validate

const ContactForm = ({ msgs }: { msgs: Msgs }) => {
    const servicesOptions = Object.values(msgs.contactPage.servicesOptions)
    return (
        <EmailForm
            initialValues={{
                name: '',
                email: '',
                phoneNumber: '',
                company: '',
                services: '',
                message: '',
                gdprConsent: false
            }}
            validate={validate}
            formName={contactFormName}
        >
            <div
                css={(th: Theme) => css`
                    @media (min-width: ${th.mediaQuery.md}) {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        grid-gap: ${th.spacing.md};
                    }
                    margin-bottom: ${th.spacing.md};
                `}
            >
                <FormField
                    comp={TextInput}
                    name="name"
                    label={t(msgs.contactPage.name)}
                    markRequired={true}
                />
                <FormField
                    comp={TextInput}
                    name="email"
                    label={t(msgs.contact.email)}
                    markRequired={true}
                />
                <FormField
                    comp={TextInput}
                    name="phoneNumber"
                    label={t(msgs.contact.phone)}
                />
                <FormField
                    comp={TextInput}
                    name="company"
                    label={t(msgs.contactPage.company)}
                    markRequired={true}
                    style={css`
                        grid-column: 1 / 3;
                        grid-row: 2;
                    `}
                />
                <FormField
                    comp={dropdownSelectWithOptions(
                        valueLabelOptions(servicesOptions)
                    )}
                    name="services"
                    label={t(msgs.contactPage.services)}
                    placeholder={t(msgs.contactPage.servicesPlaceholder)}
                />
                <FormField
                    comp={TextInput}
                    name="message"
                    label={t(msgs.contactPage.message)}
                    textArea={true}
                    rows={5}
                    markRequired={true}
                    style={css`
                        grid-column: 1 / 4;
                        grid-row: 3;
                    `}
                />
                <FormField
                    comp={(props) => <GdprConsent {...props} />}
                    name="gdprConsent"
                    type="checkbox"
                    style={css`
                        grid-column: 1 / 4;
                        grid-row: 4;
                    `}
                />
            </div>

            <SubmitPanel msgs={msgs}>{t(msgs.generalForm.submit)}</SubmitPanel>
        </EmailForm>
    )
}

export default ContactForm
