import React, { ReactChild, ReactNode } from 'react'
import Header from 'src/components/Header'
import { css } from 'src/style/css'
import { Theme } from 'src/style/Theme'

export type DataRow = {
    children: ReactNode
}

export type Labeled = {
    title: string
    children: ReactChild
}

export type CompanyData = {
    title: string
    children: ReactNode
}

export const DataRow = ({ children: value }: DataRow) => (
    <div
        css={(th: Theme) => css`
            font-size: ${th.fontSize.sm};
            margin-bottom: ${th.spacing.xs};
        `}
    >
        {value}
    </div>
)
export const Labeled = ({ title, children: value }: Labeled) => (
    <DataRow>
        <strong>{title}: </strong>
        {value}
    </DataRow>
)

const CompanyData = ({ title, children: value }: CompanyData) => (
    <div
        css={(th: Theme) => css`
            > *:first-of-type {
                margin-bottom: ${th.spacing.sm};
            }
        `}
    >
        <Header size={3}>{title}</Header>
        {value}
    </div>
)

export default CompanyData
